export const visitorValidation = (info: any, agree: boolean) => {
    const {
        site,
        type,
        name,
        phone2,
        path,
        staffName,
        team,
        carLicenseNum,
        hour,
        minute,
        rentalReason,
    } = info;

    switch (type) {
        case 'visitor':
            if (site === '') {
                alert('사이트를 선택해주세요.');
                return;
            } else if (name === '') {
                alert('이름을 입력해주세요.');
                return;
            } else if (phone2 === '') {
                alert('연락처를 입력해주세요.');
                return;
            } else if (staffName === '') {
                alert('담당자 이름을 입력해주세요.');
                return;
            } else if (path === 'car') {
                if (carLicenseNum === '') {
                    alert('차량번호를 입력해주세요.');
                    return;
                } else if (hour === '' || minute === '') {
                    alert('출차예정시간을 입력해주세요.');
                    return;
                }
            }
            break;
        case 'musinsa':
            if (team === '') {
                alert('소속부서를 입력해주세요.');
                return;
            } else if (name === '') {
                alert('이름을 입력해주세요.');
                return;
            } else if (phone2 === '') {
                alert('연락처를 입력해주세요.');
                return;
            } else if (rentalReason === '') {
                alert('대여사유를 입력해주세요.');
                return;
            }
            break;
        default:
            return;
    }
    if (agree === false) {
        alert('유의사항에 동의해주세요.');
        return;
    }
    return true;
};

export const getSiteCodeHandler = (siteName: string) => {
    if (!siteName) return;
    const code = siteName.split('무신사캠퍼스');
    if (code.length > 1) {
        return code[1];
    } else {
        return siteName;
    }
};
