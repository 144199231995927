import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useWindowSize from '@hooks/useWindowSize';
import { userSiteNameValue } from '@store';

import MainPC from '@components/feature/Main/MainPC';
import MainMobile from '@components/feature/Main/MainMobile';

const Main = () => {
    const location = useLocation();
    const { width } = useWindowSize();

    // 모바일 url param으로 site value 만들기
    useEffect(() => {
        if (location.pathname !== '/') {
            userSiteNameValue(
                `무신사캠퍼스${location.pathname
                    .replace('/', '')
                    .toUpperCase()}`,
            );
        }
    }, [location]);

    return (
        <>
            {width < 1280 && <MainMobile />}
            {width >= 1280 && <MainPC />}
        </>
    );
};

export default Main;
