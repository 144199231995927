import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { darken, lighten } from 'polished';

import Header from '@components/layout/Header';

const MainMobile = () => {
    return (
        <Container>
            <Header pageType="main" />
            <Main>
                <VisualArea>
                    <Visual>
                        <TextArea>
                            <Text>WELCOME!!</Text>
                            <Greeting>
                                무신사 방문을
                                <br />
                                환영합니다.
                            </Greeting>
                        </TextArea>
                        <AnimationRight>MUSINSA</AnimationRight>
                        <AnimationLeft>RECEPTION</AnimationLeft>
                    </Visual>
                </VisualArea>
                <Description>
                    <Title>
                        방문자 등록을 위한 <BlueText>5가지 과정</BlueText>
                    </Title>
                    <ListWrapper>
                        <List>
                            <StepMark>과정 1</StepMark>아래 방문자 등록 버튼을
                            누른 후
                        </List>
                        <List>
                            <StepMark>과정 2</StepMark>방문자 정보를 입력하고
                        </List>
                        <List>
                            <StepMark>과정 3</StepMark>유의 사항을 확인한다
                        </List>
                        <List>
                            <StepMark>과정 4</StepMark>보안 서약서에 서약 동의
                            체크하고
                        </List>
                        <List>
                            <StepMark>과정 5</StepMark>등록 완료 후, 방문증을
                            수령한다
                        </List>
                    </ListWrapper>
                </Description>
                <BottomLink>
                    <ParkingLink to="/parking">주차 등록</ParkingLink>
                    <VisitorLink to="/visitor">방문자 등록</VisitorLink>
                </BottomLink>
            </Main>
        </Container>
    );
};

export default MainMobile;

const SlideUp = keyframes`
 from {
    transform: translateY(10%);
    opacity: 0.1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const SlideRight = keyframes`
 from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const SlideLeft = keyframes`
 from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 768px;
    height: 100dvh;
`;

const Main = styled.main``;

const VisualArea = styled.section`
    width: 100%;
    height: 100vw;
    max-height: 768px;
    padding: 15px;
    color: ${(props) => props.theme.colors.bgColor};
`;

const Visual = styled.div`
    position: relative;
    padding: 28px 22px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgb(56, 56, 56);
    overflow: hidden;
`;

const TextArea = styled.div`
    animation: ${SlideUp} 0.8s ease-in-out;
    color: #ffffff;
    font-family: AppleSDGothicNeoExtraBold, sans-serif;
`;

const Text = styled.p`
    margin-bottom: 8px;
`;

const Greeting = styled.h2`
    font-size: 24px;
    line-height: 1.5;
`;

const AnimationText = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 230px;
    background-color: ${(props) => props.theme.colors.fontColor};
    font-size: 20px;
`;
const AnimationRight = styled(AnimationText)`
    bottom: 115px;
    right: -40px;
    animation: ${SlideRight} 0.8s ease-in-out;
`;

const AnimationLeft = styled(AnimationText)`
    bottom: 28px;
    left: -40px;
    animation: ${SlideLeft} 0.8s ease-in-out;
`;

const Description = styled.section`
    padding: 15px 15px 87px;
    font-family: AppleSDGothicNeoExtraBold, sans-serif;
`;

const Title = styled.h3`
    font-size: 20px;
    margin-top: 12px;
    margin-left: 20px;
    margin-bottom: 22px;
`;

const BlueText = styled.span`
    color: ${(props) => props.theme.colors.activeBlue};
`;

const ListWrapper = styled.ul`
    margin-left: 20px;
`;

const List = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const StepMark = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 46px;
    height: 25px;
    color: ${(props) => props.theme.colors.fontColor};
    background-color: ${(props) => props.theme.colors.ultraLightGrayBorder};
    border-radius: 4px;
    font-size: 12px;
    font-family: AppleSDGothicNeoHeavy, sans-serif;
`;

const BottomLink = styled.section`
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 768px;
    height: 72px;
    border-top: ${(props) => props.theme.colors.ultraLightGrayBorder} 1px solid;
    background-color: ${(props) => props.theme.colors.bgColor};
    cursor: pointer;
`;

const PageLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 4px;
    font-family: AppleSDGothicNeoExtraBold, sans-serif;
`;
const ParkingLink = styled(PageLink)`
    width: 25%;
    border: ${(props) => props.theme.colors.fontColor} 1px solid;
    background-color: ${(props) => props.theme.colors.bgColor};
    &:hover {
        background-color: ${(props) =>
            props.theme.colors.bgColor === '#000000'
                ? lighten(0.25, props.theme.colors.bgColor)
                : darken(0.05, props.theme.colors.bgColor)};
    }
`;

const VisitorLink = styled(PageLink)`
    width: calc(75% - 8px);
    background-color: ${(props) => props.theme.colors.fontColor};
    color: ${(props) => props.theme.colors.bgColor};
    &:hover {
        background-color: ${(props) =>
            props.theme.colors.fontColor === '#000000'
                ? lighten(0.25, props.theme.colors.fontColor)
                : darken(0.05, props.theme.colors.fontColor)};
    }
`;
