import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.bgColor};
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
`;

export const Logo = styled.h1`
    margin-bottom: 8px;
    font-size: 32px;
`;

export const SubTitle = styled.div`
    font-size: 18px;
    color: ${(props) => props.theme.colors.subText};
`;

export const Contents = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: calc(100vh - 100px);
    font-family: 'AppleSDGothicNeoMedium', sans-serif;
`;

export const Image = styled.img`
    display: block;
    margin-bottom: 50px;
    width: 124px;
`;

export const Title = styled.h2`
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    line-height: 1.2;
`;

export const Text = styled.div`
    margin-bottom: 60px;
    color: ${(props) => props.theme.colors.placeholder};
    text-align: center;
    line-height: 1.4;
`;

export const HomeButton = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 46px;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoBold', sans-serif;
    color: ${(props) => props.theme.colors.bgColor};
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.fontColor};
`;

export const PrevPageButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 46px;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoBold', sans-serif;
    color: ${(props) => props.theme.colors.subText};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.bgColor};
`;
