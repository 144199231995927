import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RiArrowRightLine, RiArrowRightUpLine } from 'react-icons/ri';
import { QRCodeCanvas } from 'qrcode.react';
import { TypeAnimation } from 'react-type-animation';

import { getSiteCodeHandler } from '@utils/common';
import { userSiteNameValue, siteOptionVar } from '@store';
import { SeeWorkSiteInfo } from '@graphql/types';

import Header from '@components/layout/Header';

const MainPC = () => {
    const siteName: string = useReactiveVar(userSiteNameValue);
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);

    useEffect(() => {
        if (!siteName) {
            userSiteNameValue(siteList[0]?.ws_name as string);
            localStorage.setItem(
                'selectedSite',
                siteList[0]?.ws_name as string,
            );
        }
    }, [siteList, siteName]);

    const changeSiteHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        userSiteNameValue(e.target.value);
        localStorage.setItem('selectedSite', e.target.value);
    };

    return (
        <Container>
            <Header />
            <Main>
                <Visual>
                    <Typing
                        sequence={[
                            `WELCOME TO MUSINSA\n무신사 방문을 환영합니다.`,
                            8000,
                            '',
                        ]}
                        wrapper="h2"
                        cursor={false}
                        repeat={Infinity}
                        omitDeletionAnimation={true}
                    />
                </Visual>
                <InfoArea>
                    <InfoText>
                        <Text>방문자</Text>
                        <Text>
                            등록하기 <RiArrowRightLine size={48} />
                        </Text>
                    </InfoText>
                    <LinkArea>
                        <Linkbox to="/parking">
                            <Title>주차권 발급</Title>
                            <Description>
                                주차 등록을 하시려면,
                                <br />
                                차량 주차 등록
                            </Description>
                        </Linkbox>
                        <Linkbox to="/visitor">
                            <TextWrapper>
                                <Title>방문자 등록</Title>
                                <Description>
                                    무신사 입장을 위한
                                    <br />
                                    방문자 등록
                                </Description>
                            </TextWrapper>
                            <Description>
                                모바일 등록 <RiArrowRightUpLine size={36} />
                            </Description>
                        </Linkbox>
                        <QRBox>
                            <QRCodeCanvas
                                size={190}
                                value={`${
                                    process.env.REACT_APP_DOMAIN_URL
                                }/${getSiteCodeHandler(siteName)}`}
                            />
                            <Select
                                name="campus"
                                id="campus"
                                onChange={changeSiteHandler}
                                value={siteName as string}
                            >
                                {siteList.map((list, idx: number) => (
                                    <Option
                                        key={`${idx}-${list.ws_unique}`}
                                        value={list.ws_name as string}
                                    >
                                        {list.ws_name}
                                    </Option>
                                ))}
                            </Select>
                        </QRBox>
                    </LinkArea>
                </InfoArea>
            </Main>
        </Container>
    );
};

export default MainPC;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1920px;
    height: 100vh;
    overflow: hidden;
`;

const Main = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 100px);
`;

const Visual = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 60px;
    width: 100%;
    height: calc(100% / 3 * 2);
    h2 {
        color: ${(props) => props.theme.colors.fontColor};
        text-indent: -216px;
    }
    ${(props) => props.theme.device.pcMax} {
        padding: 0 120px;
    }
`;

const Typing = styled(TypeAnimation)`
    font-size: 64px;
    white-space: pre;
    display: block;
    padding-left: 216px;
    height: 192px;
    line-height: 1.5;
    transition: font-size 0.5s;

    ${(props) => props.theme.device.pcMax} {
        height: 228px;
        font-size: 76px;
    }
`;

const InfoArea = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    width: 100%;
    ${(props) => props.theme.device.pcMax} {
        padding: 0 120px;
    }
`;

const InfoText = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 32px;
    line-height: 1.5;
    color: ${(props) => props.theme.colors.fontColor};
`;

const Text = styled.span`
    display: flex;
    align-items: center;
    svg {
        margin-left: 10px;
        padding-bottom: 5px;
    }
`;

const LinkArea = styled.div`
    display: flex;
    align-items: center;
`;

const Linkbox = styled(Link)`
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
    width: 300px;
    height: 320px;
    border: ${(props) => props.theme.colors.fontColor} 0.5px solid;
    line-height: 1.5;
    transition: all 0.5s;

    &:hover {
        transform: scale(1.03);
    }

    &:nth-of-type(1) {
        justify-content: center;
        color: ${(props) => props.theme.colors.fontColor};
        background-color: ${(props) => props.theme.colors.bgColor};
    }

    &:nth-of-type(2) {
        justify-content: space-between;
        color: ${(props) => props.theme.colors.bgColor};
        background-color: ${(props) => props.theme.colors.fontColor};
    }

    ${(props) => props.theme.device.pcMax} {
        width: 320px;
        height: 340px;
    }
`;
const QRBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 64px;
    width: 300px;
    height: 320px;
    border: ${(props) => props.theme.colors.fontColor} 0.5px solid;
    border-bottom: none;
    line-height: 1.5;
    color: ${(props) => props.theme.colors.fontColor};
    background-color: ${(props) => props.theme.colors.bgColor};
    transition: all 0.5s;

    canvas {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 172px;
        height: 172px;
    }

    ${(props) => props.theme.device.pcMax} {
        width: 320px;
        height: 340px;
    }
`;

const TextWrapper = styled.span`
    display: flex;
    flex-direction: column;
`;

const Title = styled.span`
    font-size: 32px;
`;

const Description = styled.span`
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 24px;
    font-family: AppleSDGothicNeoMedium, sans-serif;
    svg {
        margin-left: 10px;
    }
`;

const Select = styled.select`
    font-size: 24px;
    font-family: MusinsaMedium;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: AppleSDGothicNeoMedium, sans-serif;
    text-align: center;
`;

const Option = styled.option``;
