export type VisitorType = {
    type: string;
    text: string;
};

export const VISITOR_TYPE: readonly VisitorType[] = [
    { type: 'visitor', text: '방문객' },
    { type: 'musinsa', text: '무신사 피플' },
] as const;

export const VISITOR_PATH: readonly VisitorType[] = [
    { type: 'walk', text: '도보' },
    { type: 'car', text: '차량' },
] as const;
