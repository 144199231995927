import { gql } from '@apollo/client';

export const SEE_WORK_SITE = gql`
    query SeeWorkSite {
        seeWorkSite {
            ws_name
            ws_unique
            workSiteFloor
        }
    }
`;
