import React, { ChangeEvent, memo } from 'react';
import styled, { css } from 'styled-components';

type StyledCheckboxProps = {
    id: string;
    label?: string;
    fontColor?: string;
    fontFamily?: string;
    margin?: string;
    name: string;
    value?: any;
    checked?: any;
    dataValue?: string;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const StyledCheckbox = ({
    id,
    label,
    margin,
    name,
    value,
    checked,
    onChange,
    dataValue = '',
    disabled,
}: StyledCheckboxProps) => {
    return (
        <Wrapper $margin={margin}>
            <Label htmlFor={id}>
                <Marker $checked={checked} />
                <Input
                    id={id}
                    type="checkbox"
                    name={name}
                    value={value}
                    onChange={onChange}
                    data-value={dataValue}
                    disabled={disabled}
                    checked={checked}
                />
                {label && <Text>{label}</Text>}
            </Label>
        </Wrapper>
    );
};

export default memo(StyledCheckbox);

const Wrapper = styled.div<{ $margin?: string }>`
    display: flex;
    align-items: center;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;
const Label = styled.label`
    color: ${(props) => props.theme.colors.label};
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: AppleSDGothicNeoMedium, sans-serif;
`;
const Marker = styled.span<{ $checked: boolean }>`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: ${(props) => props.theme.colors.ultraLightGrayBorder} 1px solid;
    background-color: ${(props) => props.theme.colors.ultraLightGrayBorder};
    transition: border-color 0.35s;

    ${({ $checked }) =>
        $checked &&
        css`
            border: 5px solid ${(props) => props.theme.colors.activeBlue};
            background-color: ${(props) => props.theme.colors.bgColor};
        `};
`;
const Input = styled.input`
    display: none;
`;
const Text = styled.span`
    margin-left: 12px;
    padding-bottom: 2px;
`;
