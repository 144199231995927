import { Route, Routes } from 'react-router-dom';

import useWindowSize from '@hooks/useWindowSize';

import Main from '@pages/Main';
import RegisterVisitor from '@pages/RegisterVisitor';
import RegisterParking from '@pages/RegisterParking';
import NotFound from '@pages/NotFound';

const Router = () => {
    const { width } = useWindowSize();
    return (
        <Routes>
            {width < 1280 && <Route path="/*" element={<Main />} />}
            {width >= 1280 && <Route path="/" element={<Main />} />}
            {width >= 1280 && <Route path="/*" element={<NotFound />} />}
            <Route path="/visitor" element={<RegisterVisitor />} />
            <Route path="/parking" element={<RegisterParking />} />
        </Routes>
    );
};

export default Router;
