import { useReactiveVar, useQuery } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { darkMode, lightMode } from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';
import { isDarkMode, siteOptionVar } from '@store';
import { SEE_WORK_SITE } from '@graphql/queries';
import { Query } from '@graphql/types';

import Router from './Router';

function App() {
    const defaultTheme = useReactiveVar(isDarkMode);

    useQuery<Pick<Query, 'seeWorkSite'>>(SEE_WORK_SITE, {
        onCompleted: (data) => {
            siteOptionVar(data.seeWorkSite);
        },
        onError: (err) => console.log(err),
    });

    return (
        <ThemeProvider theme={defaultTheme ? darkMode : lightMode}>
            <GlobalStyles />
            <ToastContainer />
            <Router />
        </ThemeProvider>
    );
}

export default App;
