import { ApolloClient, InMemoryCache, from, ApolloError } from '@apollo/client';
import { HttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach((error) => {
            const { message, locations, path } = error;
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
            errorHandler(error as GraphQLError);
        });
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

// HTTP link talks to the server
const httpLink = new HttpLink({
    uri: process.env.REACT_APP_SERVER_URL,
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
    },
    cache: new InMemoryCache(),
});

export default client;

//에러 핸들링
const errorHandler = (error: ApolloError | GraphQLError | Error) => {
    switch (error.message) {
        // 공통 에러
        case 'err_04':
            toast.error('등록에 실패하였습니다.');
            break;
        default:
            toast.error(`server : ${error.message}`);
    }
};
