import { Link, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { RiArrowLeftLine, RiContrastFill } from 'react-icons/ri';

import useWindowSize from '@hooks/useWindowSize';
import GoBack from '@assets/icons/MobileGoBackIcon.svg';
import GoBackWhite from '@assets/icons/WhiteMobileGoBackIcon.svg';
import MIcon from '@assets/icons/MIcon.svg';
import MIconWhite from '@assets/icons/WhiteMIcon.svg';
import { isDarkMode } from '@store/index';

const Header = ({ pageType }: { pageType?: string }) => {
    const location = useLocation();
    const { pathname } = location;
    const { width } = useWindowSize();
    const darkMode = useReactiveVar(isDarkMode);

    return (
        <>
            {width < 1280 && (
                <Container $pathname={pageType}>
                    {/* 뒤로가기 분기 */}
                    {(pathname === '/visitor' || pathname === '/parking') && (
                        <Link to="/">
                            <Img
                                src={darkMode ? GoBackWhite : GoBack}
                                alt="이전 페이지로"
                            />
                        </Link>
                    )}
                    {/* 페이지 별 타이틀 */}
                    {pathname !== '/visitor' && pathname !== '/parking' && (
                        <Logo>MUSINSA RECEPTION</Logo>
                    )}

                    {pathname === '/visitor' && <Title>방문자 등록</Title>}
                    {pathname === '/parking' && <Title>주차권 발급</Title>}

                    {/* 미니아이콘 분기 */}
                    {(pathname === '/visitor' || pathname === '/parking') && (
                        <MiniLogo
                            src={darkMode ? MIconWhite : MIcon}
                            alt="무신사 모바일 아이콘"
                        />
                    )}
                </Container>
            )}
            {width >= 1280 && (
                <>
                    {pathname !== '/visitor' && pathname !== '/parking' && (
                        <Container>
                            <Logo>MUSINSA RECEPTION</Logo>
                            <ButtonArea>
                                <ModeButton
                                    onClick={() => isDarkMode(!isDarkMode())}
                                >
                                    <RiContrastFill size={18} />
                                </ModeButton>
                                {/*<Login to={''}>로그인</Login>*/}
                            </ButtonArea>
                        </Container>
                    )}
                    {(pathname === '/visitor' || pathname === '/parking') && (
                        <FormHeader>
                            <FormHeaderContainer>
                                <PrevBtn to="/">
                                    <RiArrowLeftLine size={48} />
                                </PrevBtn>
                            </FormHeaderContainer>
                        </FormHeader>
                    )}
                </>
            )}
        </>
    );
};

export default Header;

const Container = styled.header<{ $pathname?: string }>`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: ${({ $pathname }) =>
        $pathname !== 'main' ? 'space-between' : 'center'};
    align-items: center;
    padding: 0 5px;
    width: 100%;
    height: 50px;
    background-color: ${(props) => props.theme.colors.bgColor};
    z-index: 10;
    ${(props) => props.theme.device.pc} {
        justify-content: space-between;
        padding: 0 50px;
        height: 100px;
        max-width: 1920px;
    }
`;

const Title = styled.h1`
    font-size: 16px;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const Logo = styled.h1`
    font-size: 20px;
    color: ${(props) => props.theme.colors.fontColor};
    ${(props) => props.theme.device.pc} {
        font-size: 32px;
    }
`;

const Login = styled(Link)`
    color: ${(props) => props.theme.colors.lightGrayBorder};
`;

const FormHeader = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    height: 100px;
    border-bottom: ${(props) => props.theme.colors.lightGrayBorder} 1px solid;
    background-color: ${(props) => props.theme.colors.bgColor};
    z-index: 10;
`;

const FormHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 1280px;
    height: inherit;
`;

const PrevBtn = styled(Link)`
    svg {
        color: ${(props) => props.theme.colors.fontColor};
    }
`;

const Img = styled.img`
    display: block;
`;

const MiniLogo = styled(Img)`
    margin-right: 10px;
`;

const ButtonArea = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const ModeButton = styled.button`
    margin-right: 20px;
    width: 32px;
    height: 32px;
    svg {
        color: ${(props) => props.theme.colors.fontColor};
    }
`;
