import React, {
    ForwardedRef,
    ChangeEvent,
    KeyboardEvent,
    MouseEvent,
    forwardRef,
} from 'react';
import styled, { css } from 'styled-components';
import searchIcon from '@assets/icons/searchIcon.svg';
import arrowDown from '@assets/icons/arrowDown.svg';

type StyledInputProps = {
    ROW?: boolean;
    ASTERISK?: boolean;
    ARROW?: boolean;
    SEARCH?: boolean;
    BUTTON?: boolean;
    width?: string | number;
    height?: number;
    margin?: string;
    border?: string;
    fontColor?: string;
    title?: string;
    subTitle?: string;
    warning?: string;
    description?: string;
    name?: string;
    value: string;
    type?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    onInput?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onWheel?: (e: MouseEvent<HTMLInputElement>) => void;
    maxLength?: number;
    disabled?: boolean;
    placeholder?: string;
    autoComplete?: string;
};

const StyledInput = forwardRef(
    (
        {
            ROW, // 가로 여부
            ARROW, // 화살표 여부
            SEARCH, // 검색 여부
            BUTTON, // 버튼 여부
            ASTERISK, // * 여부
            width, // 가로 길이
            height = 50, // 세로 길이
            margin,
            border,
            fontColor,
            title, // 제목
            subTitle, // 부제목
            warning, // 경고
            description, // 설명
            name = '',
            value = '',
            type = 'text',
            onChange = () => null,
            onKeyDown = () => null,
            onBlur = () => null,
            onInput = () => null,
            onWheel = () => null,
            maxLength = 200,
            disabled = false,
            placeholder = '',
            autoComplete = 'off',
        }: StyledInputProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        if (ROW) {
            // 제목, Input 배치가 가로일 경우
            return (
                <Wrapper $row={ROW} $margin={margin} $width={width}>
                    {title && (
                        <RowTitle>
                            {title}
                            {ASTERISK && <Asterisk>*</Asterisk>}
                        </RowTitle>
                    )}
                    <InputBox
                        $arrow={ARROW}
                        $search={SEARCH}
                        $width={width}
                        $height={height}
                    >
                        <Input
                            ref={ref}
                            name={name}
                            value={value}
                            type={type}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            onInput={onInput}
                            onWheel={onWheel}
                            maxLength={maxLength}
                            disabled={disabled}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            $fontColor={fontColor}
                            $border={border}
                        />
                    </InputBox>
                </Wrapper>
            );
        }

        return (
            // 제목, Input 배치가 세로일 경우
            <Wrapper
                $row={ROW}
                $isDescription={!!description}
                $margin={margin}
                $width={width}
            >
                {title && (
                    <ColumnTitle>
                        {title}
                        {subTitle && <SubTitle>{subTitle}</SubTitle>}
                        {ASTERISK && <Asterisk>*</Asterisk>}
                    </ColumnTitle>
                )}
                <FlexBox>
                    <InputBox
                        $arrow={ARROW}
                        $search={SEARCH}
                        $width={width}
                        $height={height}
                    >
                        <Input
                            ref={ref}
                            name={name}
                            value={value}
                            type={type}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            onInput={onInput}
                            onWheel={onWheel}
                            maxLength={maxLength}
                            disabled={disabled}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            $fontColor={fontColor}
                            $border={border}
                        />
                    </InputBox>
                    {warning && (
                        <WarningBox $height={height}>
                            <WarningText>{warning}</WarningText>
                        </WarningBox>
                    )}
                    {description && (
                        <DescriptionBox>
                            <span>{description}</span>
                        </DescriptionBox>
                    )}
                </FlexBox>
            </Wrapper>
        );
    },
);

export default React.memo(StyledInput);

const Wrapper = styled.div<{
    $row?: boolean;
    $isDescription?: boolean;
    $width?: string | number;
    $margin?: string;
}>`
    width: ${({ $width }) => ($width ? `${$width}` : '100%')};
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    display: block;
    position: relative;

    ${({ $row }) =>
        $row &&
        css`
            display: flex;
            align-items: center;
        `};

    ${({ $isDescription }) =>
        $isDescription &&
        css`
            padding-bottom: 22px;
        `};
`;
const ColumnTitle = styled.span`
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
`;
const RowTitle = styled.span`
    width: 180px;
`;
const SubTitle = styled.span`
    margin-left: 4px;
`;
const Asterisk = styled.span`
    margin-left: 2px;
`;
const InputBox = styled.div<{
    $search?: boolean;
    $arrow?: boolean;
    $width?: string | number;
    $height?: number;
}>`
    width: 100%;
    height: ${({ $height }) => $height}px;
    display: flex;
    align-items: center;

    ${({ $search }) =>
        $search &&
        css`
            background-image: url(${searchIcon});
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: top 50% right 12px;
        `};

    ${({ $arrow }) =>
        $arrow &&
        css`
            background-image: url(${arrowDown});
            background-size: 22px;
            background-repeat: no-repeat;
            background-position: top 50% right 10px;
        `};
    & input {
        ${({ $search }) =>
            $search &&
            css`
                padding: 14px 36px 14px 12px !important;
            `};
    }
`;
const Input = styled.input<{ $fontColor?: string; $border?: string }>`
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 14px 12px;
    font-size: 15px;
    border: ${(props) =>
        props.$border
            ? props.$border
            : `${props.theme.colors.lightGrayBorder} 1px solid`};
    border-radius: 4px;
    color: ${(props) =>
        props.$fontColor ? props.$fontColor : props.theme.colors.fontColor};
    font-family: AppleSDGothicNeoMedium, sans-serif;
    &::placeholder {
        font-family: AppleSDGothicNeoMedium, sans-serif;
        font-size: 15px;
        color: ${(props) => props.theme.colors.placeholder};
    }
    &:disabled {
        color: ${(props) => props.theme.colors.placeholder};
        background-color: rgb(0, 0, 0, 0.05);
    }
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        border: ${(props) => props.theme.colors.fontColor} 1px solid;
    }
`;
const WarningBox = styled.div<{ $height?: number }>`
    height: ${({ $height }) => $height}px;
    display: flex;
    align-items: center;
    margin-left: 30px;
`;
const WarningText = styled.span`
    font-size: 14px;
`;
const DescriptionBox = styled.div`
    margin-left: 30px;
`;
const FlexBox = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
`;
