import { useReactiveVar } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { isDarkMode } from '@store';

type MuiSelectTypes = {
    name: string;
    value: any;
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | SelectChangeEvent,
    ) => void;
    options: any;
    placeholder?: string;
    width?: string;
    disabled?: boolean;
};

const MuiSelect = ({
    name,
    value,
    onChange,
    options,
    placeholder,
    width,
    disabled,
}: MuiSelectTypes) => {
    const currentTheme = useReactiveVar(isDarkMode);
    const theme = createTheme({
        palette: {
            mode: currentTheme ? 'dark' : 'light',
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <SelectWrapper
                $width={width}
                $placeholder={value}
                disabled={disabled}
            >
                <Select
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    displayEmpty
                >
                    {placeholder && (
                        <MenuItem value="" disabled>
                            {placeholder}
                        </MenuItem>
                    )}
                    {options.map((option: any, idx: number) => (
                        <MenuItem key={`${idx}-${option}`} value={option.type}>
                            {option.text}
                        </MenuItem>
                    ))}
                </Select>
            </SelectWrapper>
        </ThemeProvider>
    );
};

export default MuiSelect;

const SelectWrapper = styled(FormControl)<{
    $width?: string;
    $placeholder: string;
}>`
    width: ${({ $width }) => ($width ? `${$width}` : '100%')};
    font-family: AppleSDGothicNeoMedium, sans-serif;

    .MuiSelect-select {
        padding: 14px 12px;
        font-size: 15px;
        font-family: AppleSDGothicNeoMedium, sans-serif;
        color: ${(props) =>
            props.$placeholder.length !== 0
                ? props.theme.colors.fontColor
                : props.theme.colors.placeholder} !important;
        border: ${(props) => props.theme.colors.lightGrayBorder} 1px solid !important;
        &:hover {
            border: ${(props) => props.theme.colors.fontColor} 1px solid !important;
        }
        &.Mui-disabled {
            color: ${(props) => props.theme.colors.placeholder} !important;
            background-color: ${(props) => props.theme.colors.lightGrayBorder};
            cursor: auto !important;
            -webkit-text-fill-color: unset !important;
            &:hover {
                border: ${(props) => props.theme.colors.lightGrayBorder} 1px
                    solid !important;
            }
        }
    }
    .MuiSvgIcon-root {
        color: #aaaaaa !important;
    }
`;
