import { useNavigate } from 'react-router-dom';

import * as N from './NotFound.style';
import image404 from '@assets/images/404Img.png';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <N.Container>
            <N.Header>
                <N.Logo>MUSINSA</N.Logo>
                <N.SubTitle>리셉션</N.SubTitle>
            </N.Header>
            <N.Contents>
                <N.Image src={image404} alt="404 에러" />
                <N.Title>페이지를 찾을 수 없습니다.</N.Title>
                <N.Text>
                    페이지의 주소가 잘못 입력되었거나, <br />
                    변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
                    <br />
                    입력하신 페이지 주소를 다시 한번 확인 및<br />
                    관리자에게 문의해주세요.
                </N.Text>

                <N.HomeButton to="/">홈으로 이동</N.HomeButton>
                <N.PrevPageButton onClick={() => navigate(-1)}>
                    이전 페이지
                </N.PrevPageButton>
            </N.Contents>
        </N.Container>
    );
};

export default NotFound;
