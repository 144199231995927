import styled from 'styled-components';

type StyledSectionTypes = {
    children: React.ReactNode;
    title: string;
};
const StyledSectionMobile = ({ children, title }: StyledSectionTypes) => {
    return (
        <Section>
            <Title>{title}</Title>
            {children}
        </Section>
    );
};

export default StyledSectionMobile;

const Section = styled.section`
    padding: 30px 15px;
    border-top: ${(props) => props.theme.colors.ultraLightGrayBorder} 10px solid;
`;

const Title = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
`;
