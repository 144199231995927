import { useReactiveVar } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { userSiteNameValue, siteOptionVar } from '@store';
import arrowDown from '@assets/icons/arrowDown.svg';
import { infoTypes } from '@pages/RegisterVisitor';
import { visitorValidation } from '@utils/common';
import { SelectChangeEvent } from '@mui/material/Select';
import { VISITOR_TYPE, VISITOR_PATH } from '@constants';
import { SeeWorkSiteInfo } from '@graphql/types';

import Header from '@components/layout/Header';
import StyledInput from '@components/styled/StyledInput';
import StyledCheckbox from '@components/styled/StyledCheckbox';
import StyledButton from '@components/styled/StyledButton';
import StyledSectionMobile from '@components/styled/StyledSectionMobile';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import AgreementTerm from '@components/share/AgreementTerm';
import VowModal from '@components/share/VowModal';

type SelectList = {
    type: string;
    text: string;
};

export type VistorFormTypes = {
    openVow: boolean;
    agree: boolean;
    info: infoTypes;
    setOpenVow: React.Dispatch<React.SetStateAction<boolean>>;
    setAgree: React.Dispatch<React.SetStateAction<boolean>>;
    setInfo: React.Dispatch<React.SetStateAction<infoTypes>>;
    infoUpdateHandler: (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | SelectChangeEvent,
    ) => void;
    dateChangeHandler: (date: string) => void;
    submitHandler: () => void;
};

const VistorFormMobile = ({
    openVow,
    agree,
    info,
    setOpenVow,
    setAgree,
    setInfo,
    infoUpdateHandler,
    dateChangeHandler,
    submitHandler,
}: VistorFormTypes) => {
    const [openAgree, setOpenAgree] = useState<boolean>(false);
    const sitename: string = useReactiveVar(userSiteNameValue);
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);

    // 화면 사이즈 변동 시 전호번호 양식 변경 처리
    useEffect(() => {
        if (info.phone3.length !== 0) {
            setInfo((prev) => ({
                ...prev,
                phone2: info.phone2 + info.phone3,
                phone3: '',
            }));
        }
    }, [info.phone2, info.phone3, setInfo]);

    const inputNumberOnly = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                e.key === '-' ||
                e.key === '+' ||
                e.key === '.' ||
                e.key === 'e'
            ) {
                e.preventDefault();
            }
        },
        [],
    );

    // 유효성 체크 및 서약서 오픈 함수
    const checkValidationHandler = useCallback(() => {
        if (!visitorValidation(info, agree)) return;
        if (info.phone2.length !== 8) {
            alert('연락처를 정확히 입력해주세요.');
            return;
        }
        setOpenVow(true);
    }, [info, agree, setOpenVow]);

    return (
        <Container>
            <Header />
            <StyledSectionMobile title="방문자 등록정보">
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <Select
                            name="site"
                            id="site"
                            value={sitename.length ? sitename : info.site}
                            disabled={sitename.length ? true : false}
                            onChange={(e) => infoUpdateHandler(e)}
                        >
                            <Option value="">지점</Option>
                            {siteList.map((option, idx: number) => (
                                <Option
                                    key={`${idx}-${option.ws_unique}`}
                                    value={option.ws_name as string}
                                >
                                    {option.ws_name}
                                </Option>
                            ))}
                        </Select>
                    </FieldWrapper>
                )}
                <FieldWrapper>
                    <Select
                        name="type"
                        id="type"
                        value={info.type}
                        onChange={(e) => infoUpdateHandler(e)}
                    >
                        {VISITOR_TYPE.map((option: SelectList, idx: number) => (
                            <Option
                                key={`${idx}-${option.type}`}
                                value={option.type}
                            >
                                {option.text}
                            </Option>
                        ))}
                    </Select>
                </FieldWrapper>
                {info.type === 'musinsa' && (
                    <FieldWrapper>
                        <StyledInput
                            name="team"
                            value={info.team}
                            placeholder="소속부서"
                            onChange={(e) => infoUpdateHandler(e)}
                            maxLength={40}
                        />
                    </FieldWrapper>
                )}
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <StyledInput
                            name="company"
                            value={info.company}
                            placeholder="업체명"
                            onChange={(e) => infoUpdateHandler(e)}
                            maxLength={50}
                        />
                    </FieldWrapper>
                )}
                <FieldWrapper>
                    <StyledInput
                        name="name"
                        value={info.name}
                        placeholder="이름"
                        onChange={(e) => infoUpdateHandler(e)}
                        maxLength={20}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Select
                        name="phone1"
                        id="phone1"
                        value={info.phone1}
                        onChange={(e) => infoUpdateHandler(e)}
                        $width={'calc(35% - 8px)'}
                    >
                        <Option value="010">010</Option>
                        <Option value="011">011</Option>
                    </Select>
                    <StyledInput
                        type="number"
                        name="phone2"
                        value={info.phone2}
                        placeholder="전화번호"
                        width={'65%'}
                        onChange={(e) => infoUpdateHandler(e)}
                        onWheel={(e: React.MouseEvent<HTMLInputElement>) =>
                            e.currentTarget.blur()
                        }
                        onKeyDown={(e) => inputNumberOnly(e)}
                        maxLength={8}
                    />
                </FieldWrapper>
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <Select
                            name="path"
                            id="path"
                            value={info.path}
                            onChange={(e) => infoUpdateHandler(e)}
                        >
                            {VISITOR_PATH.map(
                                (option: SelectList, idx: number) => (
                                    <Option
                                        key={`${idx}-${option.type}`}
                                        value={option.type}
                                    >
                                        {option.text}
                                    </Option>
                                ),
                            )}
                        </Select>
                    </FieldWrapper>
                )}
            </StyledSectionMobile>
            {info.type === 'visitor' && info.path === 'car' && (
                <StyledSectionMobile title="차량번호 및 출차시간">
                    <FieldWrapper>
                        <StyledInput
                            name="carLicenseNum"
                            value={info.carLicenseNum}
                            placeholder="차량번호"
                            onChange={(e) => infoUpdateHandler(e)}
                            width="calc(69% - 8px)"
                        />
                        <TimeWrapper>
                            <StyledInput
                                type="number"
                                name="hour"
                                value={info.hour}
                                placeholder="00"
                                onChange={(e) => infoUpdateHandler(e)}
                                onWheel={(
                                    e: React.MouseEvent<HTMLInputElement>,
                                ) => e.currentTarget.blur()}
                                onKeyDown={(e) => inputNumberOnly(e)}
                                maxLength={2}
                                width="calc(50% - 7px)"
                            />
                            <AlignCenter>:</AlignCenter>
                            <StyledInput
                                type="number"
                                name="minute"
                                value={info.minute}
                                placeholder="00"
                                onChange={(e) => infoUpdateHandler(e)}
                                onWheel={(
                                    e: React.MouseEvent<HTMLInputElement>,
                                ) => e.currentTarget.blur()}
                                onKeyDown={(e) => inputNumberOnly(e)}
                                maxLength={2}
                                width="calc(50% - 7px)"
                            />
                        </TimeWrapper>
                    </FieldWrapper>
                </StyledSectionMobile>
            )}
            {info.type === 'visitor' && (
                <StyledSectionMobile title="무신사 담당자 정보">
                    <FieldWrapper>
                        <StyledInput
                            name="staffTeam"
                            value={info.staffTeam}
                            placeholder="팀"
                            onChange={(e) => infoUpdateHandler(e)}
                            width={'calc(50% - 4px)'}
                            maxLength={50}
                        />
                        <StyledInput
                            name="staffName"
                            value={info.staffName}
                            placeholder="담당자 이름"
                            onChange={(e) => infoUpdateHandler(e)}
                            width={'calc(50% - 4px)'}
                            maxLength={30}
                        />
                    </FieldWrapper>
                </StyledSectionMobile>
            )}
            {info.type === 'musinsa' && (
                <StyledSectionMobile title="반납예정일 및 대여사유">
                    <FieldWrapper>
                        <MuiDatePicker
                            value={info.returnDate}
                            onChange={(date) =>
                                dateChangeHandler(date as string)
                            }
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <StyledInput
                            name="rentalReason"
                            value={info.rentalReason}
                            placeholder="대여사유"
                            onChange={(e) => infoUpdateHandler(e)}
                            maxLength={250}
                        />
                    </FieldWrapper>
                </StyledSectionMobile>
            )}
            <StyledSectionMobile title="유의사항">
                <Agreement>
                    <GuideBold>이용안내</GuideBold>
                    <GuideText>
                        출차 예정시간을 넘어서 출차 하시는 경우,
                        <br />
                        리셉션을 방문하시어 주차권 발급
                        <br />
                        요청해주시길 바랍니다.
                    </GuideText>
                    <GuideAgree>
                        회원 본인은 예약정보 및 유의사항을 모두 확인하였으며
                        이에 동의합니다.
                    </GuideAgree>
                    <GuideText>
                        개인정보 제 3자 제공 동의
                        <SeeMoreBtn
                            onClick={() => setOpenAgree((prev) => !prev)}
                        >
                            {openAgree ? '닫기' : '보기'}
                        </SeeMoreBtn>
                    </GuideText>
                    {openAgree && <AgreementTerm site={info.site} />}
                </Agreement>
                <StyledCheckbox
                    id="agree"
                    name="agree"
                    label="예약정보 및 유의사항에 동의합니다."
                    checked={agree}
                    onChange={() => setAgree((prev) => !prev)}
                    margin={'0 0 102px'}
                />
            </StyledSectionMobile>
            <BtnArea>
                <StyledButton
                    title="정보보안 서약하기"
                    width={'100%'}
                    onClick={checkValidationHandler}
                />
            </BtnArea>
            {createPortal(
                openVow && (
                    <VowModal
                        openVow={openVow}
                        setOpenVow={setOpenVow}
                        info={info}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </Container>
    );
};

export default VistorFormMobile;

const AlignCenter = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 768px;
    height: 100dvh;
`;

const FieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;
`;

const TimeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 31%;
`;

const Select = styled.select<{ $width?: string; value?: string }>`
    padding: 0 27px 0 12px;
    width: ${({ $width }) => ($width ? $width : '100%')};
    height: 50px;
    border: ${(props) => props.theme.colors.lightGrayBorder} 1px solid;
    border-radius: 4px;
    background-image: url(${arrowDown});
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: top 50% right 12px;
    color: ${(props) =>
        props.value?.length !== 0
            ? props.theme.colors.fontColor
            : props.theme.colors.placeholder};

    &:disabled {
        color: ${(props) => props.theme.colors.placeholder};
        background-color: ${(props) => props.theme.colors.lightGrayBorder};
        cursor: auto;
    }
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        border: ${(props) => props.theme.colors.fontColor} 1px solid;
    }
`;

const Option = styled.option<{ value: string }>``;

const Agreement = styled.div`
    font-size: 14px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    word-break: keep-all;
    line-height: 1.5;
`;

const GuideBold = styled.span`
    display: block;
    margin-bottom: 5px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const GuideAgree = styled(GuideBold)`
    margin: 30px 0 20px;
`;

const GuideText = styled.p`
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.grayText};
    font-size: 13px;
`;

const SeeMoreBtn = styled.button`
    margin-left: 5px;
    color: ${(props) => props.theme.colors.grayText};
    font-size: 14px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    text-decoration: underline;
`;

const BtnArea = styled.section`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 12px;
    width: 100%;
    max-width: 768px;
    height: 72px;
    border-top: ${(props) => props.theme.colors.ultraLightGrayBorder} 1px solid;
    background-color: ${(props) => props.theme.colors.bgColor};

    ${(props) => props.theme.device.mobileMax} {
        left: 50%;
        transform: translateX(-50%);
    }
`;
