import { DefaultTheme } from 'styled-components';

const device = {
    //Mobile
    mobile: `@media (min-width: 375px) and (max-width: 768px)`,
    mobileMax: `@media (min-width: 768px)`,
    //PC
    pc: `@media (min-width: 1280px)`,
    pcMedium: `@media (min-width: 1280px) and (max-width: 1440px)`,
    pcMax: `@media (min-width: 1441px)`,
};

const commonColors = {
    // Point
    activeBlue: '#0078FF',
    // Border
    lightGrayBorder: '#E5E5E5',
    // Text
    grayText: '#6E6E6E',
    subText: '#B3B3B3',
    // Placeholder
    placeholder: '#7B7B80',
    // Term
    term: '#4F4F4F',
};

// lightMode가 default
export const lightMode: DefaultTheme = {
    colors: {
        // General
        bgColor: '#FFFFFF',
        fontColor: '#000000',
        modalBG: '#000000',
        label: '#070707', // checkbox label
        ultraLightGrayBorder: '#F3F3F3',
        ...commonColors,
    },

    device,
};

export const darkMode: DefaultTheme = {
    colors: {
        // General
        bgColor: '#000000',
        fontColor: '#ffffff',
        //색상 임의 지정
        modalBG: '#7B7B80',
        label: '#F3F3F3', // checkbox label
        ultraLightGrayBorder: '#6E6E6E',
        ...commonColors,
    },

    device,
};

export type ColorsType = {
    modalBG: string;
    bgColor: string;
    fontColor: string;
    activeBlue: string;
    placeholder: string;
    lightGrayBorder: string;
    ultraLightGrayBorder: string;
    grayText: string;
    subText: string;
    term: string;
    label: string;
};

export type DeviceType = typeof device;
